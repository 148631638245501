export default function authHeader() {
  const storedUser = localStorage.getItem("token");
  // const user = JSON.parse(storedUser ? storedUser : "");

  if (storedUser) {
    return { Authorization: "Bearer " + storedUser };
  } else {
    return {};
  }
}
