enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
}

enum Content {
  video = "Video",
  live_event = "Live Event",
  case = "Case",
  reading_material = "Reading Material",
  newsletter = "Reading Material",
  video_placeholder = "/partner/placeholders/video_placeholder.png",
  case_placeholder = "/partner/placeholders/case_placeholder.png",
  reading_material_placeholder = "/partner/placeholders/reading_material_placeholder.png",
  live_event_placeholder = "partner/placeholders/live_event_placeholder.png",
  forum_banner_placeholder = "partner/placeholders/forum_banner_placeholder.png",
  forum_placeholder = "partner/placeholders/forum_placeholder.png",
  live_event_review = "partner/review_page/Live_event_review.png"
}

enum Function {
  video = "video",
  live_event = "LiveEvent",
  case = "Case",
  reading_material = "ReadingMaterial",
  newsletter = "ReadingMaterial",
}

export { Actions, Mutations, Content, Function };
